import React from 'react';
import Layout from '../components/Layout';
import Breadcrums from "../components/Breadcrums";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(..//images/levenmas/servicio_consulto.jpg)`}}
                    >
                        <h1>Servicio de Consultoría Fiscal</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <Breadcrums name="Servicio de Consultoría Fiscal"/>

                        <div className="card">
                            <div className="card__content">
                                <h1 style={{color: '#000000', fontSize: 20}}>
                                    Servicio de Consultoría Fiscal
                                </h1>
                                <ul style={{color: '#000000'}}>
                                    <li>Planeación estratégica tributaria</li>
                                    <li>Requerimiento y Control de Obligaciones Fiscales</li>
                                    <li>Diagnostico Fiscal del contribuyente</li>
                                    <li>Representación Fiscal</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
